import './Home.css';
import {Link} from 'react-router-dom';
import React, {useEffect} from 'react';
import video from '../video/SmokeEffect.mp4'
function HomePage(){
    useEffect(() =>{
        window.scrollTo(0, 0);
      },[])

        return(
        <div className='hero'>
            <video  preload="true" controls autoPlay muted loop>
                                <source src={video} type="video/mp4"></source>
            </video>
            <div className='content'>
                <div className="homeContainer">
                    <div className="cfUnderlayer">
                        <Link to="/about">
                            <div  id="cf"></div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
            
    );
}

export default HomePage;