import React from 'react'
import './Footer.css'
import {Link, useLocation} from 'react-router-dom'

function Footer() {

  const {pathname} = useLocation();

  if(pathname === "/") return "";
  return (
    <div className='footerContainer space-between black'>
      <div className='footer-content-container'>
        <div><p>Dampfende Wildsau e.V.</p></div>
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link> 
      </div>
        
    </div>
  )
}

export default Footer