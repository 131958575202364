import React, {useState, useEffect} from 'react'
import './Galerie.css'
import {slice, concat} from 'lodash'
import {
    GreenOutdor, 
 BilliardZoomed, 
 LaserSquidRave, 
 HalloweenRabbid, 
 HalloweenFaces, 
 HalloweenMoreFaces, 
 DiscoBall, 
 LogoLEDRed, 
 VereinsheimLaserSmoke, 
 OutdoorHügel, 
 ClubOutdoorDay, 
 Anlage, 
 VereinsheimEvening, 
 VereinsheimChristmas, 
 VereinsheimChristmas2, 
 Taxi, 
 DarkArt, 
 WildsauArtLight, 
} from '../images/imagesLens/index'
import ImageCenter from './ImageCenter'

const GalerieImages = [
    LaserSquidRave, 
    GreenOutdor, 
    BilliardZoomed,
    HalloweenRabbid, 
    HalloweenFaces, 
    HalloweenMoreFaces, 
    DiscoBall, 
    Anlage,
    LogoLEDRed, 
    VereinsheimLaserSmoke, 
    OutdoorHügel, 
    ClubOutdoorDay, 
    VereinsheimEvening, 
    VereinsheimChristmas, 
    VereinsheimChristmas2, 
    Taxi, 
    DarkArt, 
    WildsauArtLight,]

const ImageDescription = [
    "Kunterbunte Lasershow",
    "Tanzen bis einem Grün wird",
    "Präzision & Flare",
    "Ostern?",
    "There is no shadow without light",
    "Quelle der Musik",
    "There needs to be light on top of light",
    "Anlage Stand 2021",
    "",
    "Weihnachtsfeier 2021",
    "Abfahrt",
    "Unser Nest",
    "Abendambiente",
    "Vereinsheim 21",
    "Vereinsheim 21",
    "Abfahrt: 16:20 Uhr",
    "Art is a form of expression",
    "Dampfende Wildsau"
]

const LENGTH = 18;
const LIMIT = 6;

function GaleriePage(props) {

    const {width} = props.dimensions
    useEffect(() =>{
        window.scrollTo(0, 0);
      },[])

    const [showMore, setShowMore] = useState(true)
    const [list, setList] = useState(slice(GalerieImages, 0, LIMIT))
    const [index, setIndex] = useState(LIMIT)
    const [imagePopUp, setImagePopUp] = useState()
    const [description, setDescription] = useState()
    let [showPopup, setShowPopup] = useState(false);

    const loadMore = () => {
        const newIndex = index + LIMIT;
        const newShowMore = newIndex <= (LENGTH -1);
        const newList = concat(list, slice(GalerieImages, index, newIndex));
        setIndex(newIndex)
        setList(newList)
        setShowMore(newShowMore)
    }

    const centerImageOnClick = (event) =>{
        let image = event.target;
        setImagePopUp(image.src)
        setDescription(image.alt)
        setShowPopup(true)
    }

  return (
    <div className='galerie'>
        <div className="galerieContainer">
        {/*
            <div className="smokeHeaderGalerie">
                    <div className='smokeBodyGalerie'>
                        <h2 id="galerieHeader" className='smokeFilterGalerie'>Galerie</h2>
                    </div>
                    <svg width="0">
                        <filter id="filter">
                            <feTurbulence id="turbulence" type="fractalNoise" baseFrequency=".04" numOctaves="30" />
                            <feDisplacementMap in="SourceGraphic" scale="15" />
                        </filter>
                    </svg>
            </div>
        */}
            <div className='galerieContent container'>
                <ul className="image-gallery">
                    {
                        list.map((image, index) => {
                            return(
                                <li key={index}>
                                    <img className='imageSize' alt={ImageDescription[index]} src={image.default} onClick={width > 425 ? centerImageOnClick : () => ""}/>
                                </li>
                            )
                        })
                    }
                    
                </ul>
                
            </div>
            {showMore && <div className='loadMoreButtonContainer'>
                                    <button className="glow-on-hover" onClick={loadMore}>Load More</button>
                                </div>}
            <ImageCenter show={showPopup} closePopUp={() =>{setShowPopup(false)}} img={imagePopUp} description={description}/>
        </div>
    </div>
  )
}

export default GaleriePage