import React, { useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom';
import './Navbar.scss'

function Navbar(props) {

    const {pathname} = useLocation();    
    const {width} = props.dimensions
    
        useEffect(() => {
            if(pathname === "/") return;
            const navbarToggle = document.getElementById("navbar-toggle")
            const navbarMenu = document.getElementById("navbar-menu")
            const navbarLinksContainer = document.getElementById("navbar-links")
            let isNavbarExpanded = navbarToggle.getAttribute("aria-expanded") === true
    
            const toggleNavbarVisibility = () => {
                isNavbarExpanded = !isNavbarExpanded
                navbarToggle.setAttribute("aria-expanded", isNavbarExpanded)
            }
    
            navbarToggle.addEventListener("click", toggleNavbarVisibility)
            navbarLinksContainer.addEventListener("click", (e) =>  e.stopPropagation());
            navbarMenu.addEventListener("click", toggleNavbarVisibility)
            
        },[pathname])    
    

    /*
    const [scrollPosition, setScrollPosition] = useState(0)
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position)
    }

    useEffect(() =>{
        window.addEventListener("scroll", handleScroll);
    }, [])
    */

    if(pathname === '/') return null;
  return(
    <div id="navbar">
        <nav className="navbar-container container">
            <a href="/" className="home-link">
                <div className="navbar-logo"></div>
                Musikkultur Dampfende Wildsau e.V.
            </a>
            <button type="button" id="navbar-toggle" aria-controls="navbar-menu" aria-label="Toggle menu" aria-expanded="false">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            </button>
            <div id="navbar-menu" aria-labelledby="navbar-toggle">
                <ul id="navbar-links" className="navbar-links">
                    <li className="navbar-item"><Link className={`navbar-link ${pathname==="/about" && width > "425" ? "active" : ""}`} to="/about">About</Link></li>
                    <li className="navbar-item"><Link className={`navbar-link ${pathname==="/galerie" && width > "425" ? "active" : ""}`}  to="/galerie">Galerie</Link></li>
                    <li className="navbar-item"><Link className={`navbar-link ${pathname==="/kontakt" && width > "425" ? "active" : ""}`}  to="/kontakt">Kontakt</Link></li>
                </ul>
            </div>
        </nav>
    </div>
  ) 
}

export default Navbar


