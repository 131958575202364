import React, {useState, useEffect} from 'react';
import './Kontakt.css';
import emailjs from 'emailjs-com'

function ContactPage(){

    useEffect(() =>{
        window.scrollTo(0, 0);
      },[])

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [text, setText] = useState();

    const sendEmail = async (e) => {
        e.preventDefault(); //stop reload
        let nameInput = document.getElementById('name');
        let mailInput = document.getElementById('email')
        let messageInput = document.getElementById('message')
        emailjs.sendForm('service_cdn972h', 'template_fnz9t3b',e.target, 'QULzvBoHfHLsYnpoZ')
        .then(res => console.log(res))
        .catch(err => console.log(err));

        alert("Vielen Dank für deine Nachricht, wir kommen in Kürze auf dich zurück.")

        nameInput.value = ""; mailInput.value =""; messageInput.value = "";
    }




    return(
        <div className='contact'>
            <div className="contactContainer">
                <div className='contactForm'>
                    <form onSubmit={sendEmail}>
                        <div className='smallContainer'>
                            <input className="inputStyle" onChange={(e) =>  setName(e.target.value)} type="text" id="name" name='name' placeholder="Name" required />
                        </div>
                        <div className='smallContainer'>
                            <input className="inputStyle" onChange={(e) =>  setEmail(e.target.value)} type="email" id="email" name='user_email' placeholder="Email" required />
                        </div>
                        <div className='bigContainer'>
                            <textarea className="textStyle" onChange={(e) =>  setText(e.target.value)} id="message" rows="8" name="message" placeholder="Nachricht..." required />
                        </div>
                        
                        <button disabled={!name && !email && !text } className="glow-on-hover" type='submit'>Senden</button>
                    </form>
                </div>
                
            </div>
        </div>
    );
}

export default ContactPage;