import React, { useEffect, useRef } from 'react'

function ImageCenter(props) {

  const ref = useRef(null);
  const {closePopUp} = props;

  useEffect(() =>{
    const handleClickOutside = (event) =>{
      if(ref.current && !ref.current.contains(event.target)){
        closePopUp && closePopUp();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  },[closePopUp])

  if(!props.show) return null;

  return /*props.trigger? */ (
    <div ref={ref} className='centered-image-container'>
        <div className='centered-image'>
            <img src={props.img} alt={props.description}/>
        </div>
        <div className="image-description">
          <div className="description-container">
             {props.description}
          </div>
        </div>
    </div>
  )
}

export default ImageCenter