import React, { useEffect, useState } from 'react';
import './App.css';
import HomePage from './HomePage/HomePage.js';
import AboutPage from './AboutPage/AboutPage.js';
import KontaktPage from './KontaktPage/KontaktPage.js';
import Footer from './Footer/Footer';
import GaleriePage from './GaleriePage/GaleriePage';
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom'
import Datenschutzerklaerung from './FooterContent/Datenschutzerklaerung';
import Impressum from './FooterContent/Impressum';
import Navbar from './Navbar/Navbar';

function App() {

  const [dimensions, setDimension] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    function handleResize(){
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    window.addEventListener('resize', handleResize)

    return _ => window.removeEventListener('resize', handleResize)
  },[])

  /*useEffect(() => {
    console.log("resized: ", dimensions)
  },[dimensions])*/
  return (
    <Router>
      <div className="App">

          <Navbar dimensions={dimensions}/>

          <Routes>
            <Route exact path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/galerie" element={<GaleriePage dimensions={dimensions}/>}/>
            <Route path="/kontakt" element={<KontaktPage/>}/>
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/datenschutzerklaerung" element={<Datenschutzerklaerung/>}/>
          </Routes>

          <Footer/>

      </div>
      
    </Router>
  );
}

export default App;
