import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './fonts/GOVER.ttf';

ReactDOM.render(
                  <App />,
  document.getElementById('root')
);

