export const GreenOutdor = require('../imagesLens/DSC00725.jpg')
export const BilliardZoomed = require('../imagesLens/DSC01446.jpg')
export const LaserSquidRave = require('../imagesLens/DSC01600.jpg')
export const HalloweenRabbid = require('../imagesLens/DSC01673.jpg')
export const HalloweenFaces = require('../imagesLens/DSC01744.jpg')
export const HalloweenMoreFaces = require('../imagesLens/DSC01751.jpg')
export const DiscoBall = require('../imagesLens/DSC02329.jpg')
export const LogoLEDRed = require('../imagesLens/DSC02403.jpg')
export const VereinsheimLaserSmoke = require('../imagesLens/DSC02468.jpg')
export const OutdoorHügel = require('../imagesLens/DSC03275.jpg')
export const ClubOutdoorDay = require('../imagesLens/IMG_20200829_195816.jpg')
export const Anlage = require('../imagesLens/IMG_20210628_203502.jpg')
export const OutdoorFloorChalk = require('../imagesLens/IMG_20210731_164233.jpg')
export const VereinsheimEvening = require('../imagesLens/IMG_20210809_234114.jpg')
export const VereinsheimChristmas = require('../imagesLens/IMG_20211216_201525.jpg')
export const VereinsheimChristmas2 = require('../imagesLens/IMG_20211216_201613.jpg')
export const Taxi = require('../imagesLens/IMG_20220403_172505.jpg')
export const DarkArt = require('../imagesLens/IMG-20200105-WA0005.jpg')
export const WildsauArtLight = require('../imagesLens/IMG-20200118-WA0002.jpg')
