import React, {useEffect} from 'react'

function Impressum() {
  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div className='container text-container'>
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>Musikkultur Dampfende Wildsau e.V.</p>
        <h3>Postanschrift:</h3>
        <p>Wulmersreuth 12<br/>95237 Weißdorf</p>
        <h3>Kontakt:</h3>
        <p>E-Mail:<a href="mailto:musikkultur@dampfendewildsau.de">musikkultur@dampfendewildsau.de</a></p>
        <p></p><h3>Vertreten durch:</h3>
        <p>Simon Dannert
        <br/>Dominik Tögel</p>
        <p></p><h3>Eingetragen am</h3>
        <p>Amtsgericht Hof<br/>VR 200508</p>
        <p></p><h3>Umsatzsteuer-Identifikationsnummer</h3>
        <p>DE 132954961<br/></p>
        <p><br/></p>
        <p></p><h2>Hinweise zur Website</h2>
        <p></p><h2>Information gemäß § 36 VSBG</h2>
        <p>Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung in Verbrauchersachen) erklärt der Betreiber dieser Website:</p>
        <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <p></p><p><em>Das Impressum wurde mit dem <a href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind AG</a> erstellt.</em></p>
    </div>
  )
}

export default Impressum