import React, { useEffect } from 'react'

function Datenschutzerklaerung() {

  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])
  
  return (
    <div className="container text-container">
    <h1 class="adsimple-312107696">Datenschutzerklärung</h1>
<h2 id="einleitung-ueberblick" class="adsimple-312107696">Einleitung und Überblick</h2>
<p>Wir haben diese Datenschutzerklärung (Fassung 28.08.2022-312107696) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-312107696" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312107696#d1e2269-1-1" target="_blank" rel="noopener noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
<strong class="adsimple-312107696">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
<p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-312107696">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und <strong class="adsimple-312107696">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
<h2 id="anwendungsbereich" class="adsimple-312107696">Anwendungsbereich</h2>
<p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
<ul class="adsimple-312107696">
<li class="adsimple-312107696">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li class="adsimple-312107696">Social Media Auftritte und E-Mail-Kommunikation</li>
<li class="adsimple-312107696">mobile Apps für Smartphones und andere Geräte</li>
</ul>
<p>
<strong class="adsimple-312107696">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 id="rechtsgrundlagen" class="adsimple-312107696">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-312107696" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li class="adsimple-312107696">
<strong class="adsimple-312107696">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li class="adsimple-312107696">
<strong class="adsimple-312107696">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
<li class="adsimple-312107696">
<strong class="adsimple-312107696">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li class="adsimple-312107696">
<strong class="adsimple-312107696">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul class="adsimple-312107696">
<li class="adsimple-312107696">In <strong class="adsimple-312107696">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-312107696">Datenschutzgesetz</strong>), kurz <strong class="adsimple-312107696">DSG</strong>.</li>
<li class="adsimple-312107696">In <strong class="adsimple-312107696">Deutschland</strong> gilt das <strong class="adsimple-312107696">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-312107696"> BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
<h2 id="kontaktdaten-verantwortliche" class="adsimple-312107696">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
<span class="adsimple-312107696">Musikkultur Dampfende-Wildsau e.V.<br />
Dejan Fraas<br />
Wulmersreuth 12 , 95237 Weißdorf, Deutschland</span>
</p>
<p>E-Mail: <a href="mailto:musikkultur@dampfendewildsau.de">musikkultur@dampfendewildsau.de</a>
</p>
<p>Impressum: <a href="https://www.dampfendewildsau.de/impressum/">https://www.dampfendewildsau.de/impressum/</a>
</p>
<h2 id="speicherdauer" class="adsimple-312107696">Speicherdauer</h2>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
<p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
<p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 id="rechte-dsgvo" class="adsimple-312107696">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul class="adsimple-312107696">
<li class="adsimple-312107696">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul class="adsimple-312107696">
<li class="adsimple-312107696">zu welchem Zweck wir die Verarbeitung durchführen;</li>
<li class="adsimple-312107696">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li class="adsimple-312107696">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li class="adsimple-312107696">wie lange die Daten gespeichert werden;</li>
<li class="adsimple-312107696">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li class="adsimple-312107696">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
<li class="adsimple-312107696">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li class="adsimple-312107696">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li class="adsimple-312107696">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
<li class="adsimple-312107696">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
<li class="adsimple-312107696">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
<li class="adsimple-312107696">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
<li class="adsimple-312107696">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
<ul class="adsimple-312107696">
<li class="adsimple-312107696">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
<li class="adsimple-312107696">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
<li class="adsimple-312107696">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
</ul>
</li>
<li class="adsimple-312107696">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
<li class="adsimple-312107696">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
</ul>
<p>
<strong class="adsimple-312107696">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-312107696" href="https://www.dsb.gv.at/?tid=312107696" target="_blank" rel="noopener noreferrer">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-312107696" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
<h2 id="bayern-datenschutzbehoerde" class="adsimple-312107696">Bayern Datenschutzbehörde</h2>
<p>
<strong class="adsimple-312107696">Landesbeauftragter für Datenschutz:</strong> Prof. Dr. Thomas Petri<br />
<strong class="adsimple-312107696">Adresse:</strong> Wagmüllerstr. 18, 80538 München<br />
<strong class="adsimple-312107696">Telefonnr.:</strong> 089/21 26 72-0<br />
<strong class="adsimple-312107696">E-Mail-Adresse:</strong> poststelle@datenschutz-bayern.de<br />
<strong class="adsimple-312107696">Website: </strong>
<a class="adsimple-312107696" href="https://www.datenschutz-bayern.de/?tid=312107696" target="_blank" rel="noopener noreferrer">https://www.datenschutz-bayern.de/</a>
</p>
<h2 id="sicherheit-datenverarbeitung" class="adsimple-312107696">Sicherheit der Datenverarbeitung</h2>
<p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
<p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
<h2 id="tls-verschluesselung-https" class="adsimple-312107696">TLS-Verschlüsselung mit https</h2>
<p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
<p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (<a class="adsimple-312107696" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312107696" target="_blank" rel="noopener noreferrer">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" alt="content"/> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
<h2 id="kommunikation" class="adsimple-312107696">Kommunikation</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-312107696">Kommunikation Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
&#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
&#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
&#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
<h3 class="adsimple-312107696">Betroffene Personen</h3>
<p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<h3 class="adsimple-312107696">Telefon</h3>
<p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-312107696">E-Mail</h3>
<p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-312107696">Online Formulare</h3>
<p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-312107696">Rechtsgrundlagen</h3>
<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul class="adsimple-312107696">
<li class="adsimple-312107696">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
<li class="adsimple-312107696">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li class="adsimple-312107696">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
</ul>
<h2 id="webhosting-einleitung" class="adsimple-312107696">Webhosting Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-312107696">Webhosting Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
&#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-312107696">Was ist Webhosting?</h3>
<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<p>
<img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
</p>
<h3 class="adsimple-312107696">Warum verarbeiten wir personenbezogene Daten?</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li class="adsimple-312107696">Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li class="adsimple-312107696">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li class="adsimple-312107696">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
</ol>
<h3 class="adsimple-312107696">Welche Daten werden verarbeitet?</h3>
<p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul class="adsimple-312107696">
<li class="adsimple-312107696">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
<li class="adsimple-312107696">Browser und Browserversion (z. B. Chrome 87)</li>
<li class="adsimple-312107696">das verwendete Betriebssystem (z. B. Windows 10)</li>
<li class="adsimple-312107696">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a class="adsimple-312107696" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="follow noopener noreferrer">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
<li class="adsimple-312107696">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li class="adsimple-312107696">Datum und Uhrzeit</li>
<li class="adsimple-312107696">in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3 class="adsimple-312107696">Wie lange werden Daten gespeichert?</h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
<p>
<strong class="adsimple-312107696">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3 class="adsimple-312107696">Rechtsgrundlage</h3>
<p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
<h2 id="audio-video-einleitung" class="adsimple-312107696">Audio &amp; Video Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-312107696">Audio &amp; Video Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
Mehr Details dazu finden Sie weiter unten in den entsprechenden Datenschutztexten.<br />
&#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den Dienstzweck nötig sind<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-312107696">Was sind Audio- und Videoelemente?</h3>
<p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie sich direkt über unsere Website etwa Videos ansehen oder Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern zur Verfügung gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter bezogen.</p>
<p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in der Regel kostenlos, es können aber auch kostenpflichtige Inhalte veröffentlicht werden. Mit Hilfe dieser eingebundenen Elemente könne Sie sich über unsere Website die jeweiligen Inhalte anhören oder ansehen.</p>
<p>Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können auch personenbezogene Daten von Ihnen an die Dienstanbieter übermittelt, verarbeitet und gespeichert werden.</p>
<h3 class="adsimple-312107696">Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</h3>
<p>Natürlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass Inhalte nicht mehr bloß in Text und statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu einem Video zu geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate, die unterhaltend oder informativ und im Idealfall sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video und/oder Audio-Inhalte an.</p>
<h3 class="adsimple-312107696">Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
<p>Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein eingebettetes Video hat, verbindet sich Ihr Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter übertragen und dort gespeichert. Manche Daten werden ganz unabhängig davon, ob Sie bei dem Drittanbieter ein Konto haben oder nicht, gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse, Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu Ihrem Endgerät. Weiters werden von den meisten Anbietern auch Informationen über Ihre Webaktivität eingeholt. Dazu zählen etwa Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt haben oder über welche Website Sie den Dienst nutzen. All diese Informationen werden meist über Cookies oder Pixel-Tags (auch Web Beacon genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem Browser gespeichert. Welche Daten genau gespeichert und verarbeitet werden, erfahren Sie stets in der Datenschutzerklärung des jeweiligen Anbieters.</p>
<h3 class="adsimple-312107696">Dauer der Datenverarbeitung</h3>
<p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erfahren Sie entweder weiter unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerklärung des Anbieters. Grundsätzlich werden personenbezogene Daten immer nur so lange verarbeitet, wie es für die Bereitstellung unserer Dienstleistungen oder Produkte unbedingt nötig wird. Dies gilt in der Regel auch für Drittanbieter. Meist können Sie davon ausgehen, dass gewisse Daten über mehrere Jahre auf den Servern der Drittanbieter gespeichert werden. Daten können speziell in Cookies unterschiedlich lange gespeichert werden. Manche Cookies werden bereits nach dem Verlassen der Website wieder gelöscht, anderen können über einige Jahre in Ihrem Browser gespeichert sein.</p>
<h3 class="adsimple-312107696">Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum Widerruf bleibt unberührt.</p>
<p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet werden, sollte Sie sich auch unsere allgemeine Datenschutzerklärung über Cookies durchlesen. In den Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie genaueres über den Umgang und die Speicherung Ihrer Daten.</p>
<h3 class="adsimple-312107696">
<strong class="adsimple-312107696">Rechtsgrundlage</strong>
</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio- und Video-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-312107696">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-312107696">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<h2 id="soundcloud-datenschutzerklaerung" class="adsimple-312107696">SoundCloud Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong class="adsimple-312107696">SoundCloud Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den Dienstzweck nötig sind<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-312107696">Was ist SoundCloud?</h3>
<p>Wir verwenden auf unserer Website Funktionen (Widgets) des Social Media Netzwerks SoundCloud der Firma SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland. Sie erkennen die Widgets am bekannten orangen Logo. Durch die Verwendung von Funktionen wie beispielsweise dem Abspielen von Musik werden Daten an SoundCloud übermittelt, gespeichert und ausgewertet. In dieser Datenschutzerklärung zeigen wir Ihnen, um welche Daten es sich handelt, warum wir SoundCloud verwenden und wie Sie Ihre Daten bzw. die Datenübertragung verwalten oder unterbinden können.</p>
<p>Das Social-Media-Netzwerk SoundCloud ist eine Online-Musikplattform, die dem Austausch und der Verteilung von Audiodateien dient. Auf SoundCloud bieten Musiker oder Podcaster ihre Audiodateien zum Downloaden an. Zudem kann man mit SoundCloud die Audiodateien auch in anderen Websites einbinden. Und genau das haben auch wir gemacht. Typisch für SoundCloud sind die grafischen Darstellungen der Audiodateien in Wellenform und die Kommentarleiste. So können angemeldete User Musikstücke bzw. Podcasts jederzeit anhören und kommentieren.</p>
<h3 class="adsimple-312107696">Warum verwenden wir SoundCloud auf unserer Website?</h3>
<p>Unser Ziel ist es, Ihnen auf unserer Webseite den bestmöglichen Service zu liefern. Damit meinen wir nicht nur unsere Produkte oder Dienstleistungen. Zu einem gesamtheitlichen Kundenservice zählt auch, wie wohl Sie sich auf unserer Website fühlen und wie hilfreich unsere Website für Sie ist. Durch die eingebettete SoundCloud-Abspielfunktion können wir Ihnen akustischen Content direkt und frei Haus liefern. Sie müssen nicht zuerst irgendeinen Link folgen, um sich eine Audiodatei anzuhören, sondern können gleich über unsere Webseite starten.</p>
<h3 class="adsimple-312107696">Welche Daten werden auf SoundCloud gespeichert?</h3>
<p>Sobald Sie eine unserer Webseiten besuchen, die ein Widget (Like- oder Share-Button oder Abspielfunktion) eingebaut hat, verbindet sich Ihr Browser mit einem SoundCloud-Server. Dabei können Daten von Ihnen an SoundCloud übertragen, dort verwaltet und gespeichert werden. Zum Beispiel erfährt SoundCloud auf diese Weise Ihre IP-Adresse und welche Seite (in diesem Fall unsere) Sie wann besucht haben. Wenn Sie ein SoundCloud-Konto haben und angemeldet sind, während Sie auf unserer Webseite surfen, werden die erhobenen Daten direkt Ihrem Konto/Account zugewiesen. Das können Sie nur verhindern, indem Sie sich, während Ihres Aufenthalts auf unserer Website, von SoundCloud abmelden. Neben den oben genannten Informationen werden in den Cookies auch Daten über Ihr Userverhalten gespeichert. Wann immer Sie beispielsweise einen Button klicken, ein Musikstück abspielen oder pausieren wird diese Information in den Cookies gespeichert. Das Widget bzw. SoundCloud ist somit in der Lage, Sie zu erkennen und manchmal wird das Widget auch verwendet, um Ihnen personalisierte Inhalte zu liefern. SoundCloud verwendet nicht nur eigene Cookies, sondern auch Cookies von Drittanbietern wie Facebook oder Google Analytics. Diese Cookies dienen dem Unternehmen mehr Informationen über Ihr Verhalten auf externen Websites und der eigenen Plattform zu erhalten. Wir als Websitebetreiber bekommen durch die verwendeten Cookies von SoundCloud keine Informationen über Ihr Userverhalten. Die Datenübertragung und daher auch die Informationen zu technischen Geräten und Ihrem Verhalten auf der Webseite findet zwischen Ihnen und SoundCloud statt.</p>
<p>Im Folgenden zeigen wir Cookies, die gesetzt wurden, wenn man auf eine Webseite geht, die SoundCloud-Funktionen eingebunden hat. Diese Liste ist nur ein Beispiel möglicher Cookies und kann keinen Anspruch auf Vollständigkeit erheben. Bei diesem Beispiel hat der User kein SoundCloud-Konto:</p>
<p>
<strong class="adsimple-312107696">Name: </strong>sc_anonymous_id<br />
<strong class="adsimple-312107696">Wert:</strong> 208165-986996-398971-423805312107696-0<br />
<strong class="adsimple-312107696">Verwendungszweck:</strong> Dieses Cookie macht es erst möglich, Dateien oder andere Inhalte in Websites einzubinden und speichert eine User-ID.<br />
<strong class="adsimple-312107696">Ablaufdatum: </strong>nach 10 Jahren</p>
<p>
<strong class="adsimple-312107696">Anmerkung:<br />
</strong>Das Cookie sc_anonymous_id wird sofort gesetzt, wenn Sie auf einer unserer Webseiten sind, die eine Soundcloud-Funktion eingebaut hat. Dafür müssen Sie mit der Funktion noch nicht interagieren.</p>
<p>
<strong class="adsimple-312107696">Name: </strong>__qca<br />
<strong class="adsimple-312107696">Wert:</strong> P0-1223379886-1579605792812312107696-7<br />
<strong class="adsimple-312107696">Verwendungszweck:</strong> Dieses Cookie ist ein Drittanbieter-Cookie von Quantcast und sammelt Daten wie beispielsweise wie oft Sie die Seite besuchen oder wie lange Sie auf der Seite bleiben. Die gesammelten Informationen werden dann an SoundCloud weitergegeben.<br />
<strong class="adsimple-312107696">Ablaufdatum: </strong>nach einem Jahr</p>
<p>
<strong class="adsimple-312107696">Name:</strong> Sclocale<br />
<strong class="adsimple-312107696">Wert:</strong> de<br />
<strong class="adsimple-312107696">Verwendungszweck:</strong> Das Cookie speichert die Spracheinstellung, die Sie voreingestellt haben.<br />
<strong class="adsimple-312107696">Ablaufdatum: </strong>nach einem Jahr</p>
<p>
<strong class="adsimple-312107696">Name: </strong>_soundcloud_session<br />
<strong class="adsimple-312107696">Wert:</strong> /<br />
<strong class="adsimple-312107696">Verwendungszweck:</strong> Zu diesem Cookie konnten wir keine konkreten Informationen in Erfahrung bringen.<br />
<strong class="adsimple-312107696">Ablaufdatum: </strong>nach Sitzungsende</p>
<p>
<strong class="adsimple-312107696">Name: </strong>_session_auth_key<br />
<strong class="adsimple-312107696">Wert:</strong> /<br />
<strong class="adsimple-312107696">Verwendungszweck:</strong> Mit Hilfe des Cookies können Sitzungsinformationen (also Userverhalten) gespeichert werden und eine Client-Anfrage authentifiziert werden.<br />
<strong class="adsimple-312107696">Ablaufdatum: </strong>nach 10 Jahren</p>
<p>Zudem verwendet SoundCloud auch weitere Drittanbieter-Cookies wie _fbp, _ga, gid von Facebook und Google Analytics. All die in den Cookies gespeicherten Informationen nutzt SoundCloud, um die eigenen Dienste zu verbessern und personalisierte Werbung auszuspielen.</p>
<h3 class="adsimple-312107696">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Grundsätzlich bleiben die erhobenen Daten bei SoundCloud gespeichert, solange ein User-Konto besteht oder es für SoundCloud nötig ist, um die betriebswirtschaftlichen Ziele zu erreichen. Wie lange genau gespeichert wird ändert sich abhängig vom Kontext und den rechtlichen Verpflichtungen. Auch wenn Sie kein Konto haben und personenbezogene Daten gespeichert wurden, haben Sie das Recht die Datenlöschung zu beantragen.</p>
<h3 class="adsimple-312107696">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Wenn Sie ein SoundCloud-Konto haben, können Sie über „Einstellungen“ die Datenverarbeitung verwalten bzw. Ihr ganzes Konto löschen. Sie können aber auch Cookies in Ihrem Browser genau nach Ihren Ansprüchen verwalten, löschen oder deaktivieren. Die Herangehensweise hängt immer von Ihrem verwendeten Browser ab. Falls Sie sich zum Löschen oder Deaktivieren von Cookies entscheiden, nehmen Sie bitte zu Kenntnis, dass dann möglicherweise nicht mehr alle Funktionen verfügbar sind. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3 class="adsimple-312107696">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene SoundCloud-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-312107696">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-312107696">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen SoundCloud-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. SoundCloud setzt auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Wir hoffen, wir haben Ihnen einen guten Überblick über den Datenverkehr durch SoundCloud geliefert. Wenn Sie mehr über die Datenschutzrichtlinien und dem allgemeinen Umgang mit Daten durch SoundCloud erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung des Unternehmens unter <a class="adsimple-312107696" href="https://soundcloud.com/pages/privacy?tid=312107696" target="_blank" rel="noopener noreferrer">https://soundcloud.com/pages/privacy</a>.</p>
<p>Alle Texte sind urheberrechtlich geschützt.</p>
<p >Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple</p>
</div>
  )

}

export default Datenschutzerklaerung